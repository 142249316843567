import Input from '../types/input.type';
import Template from '../types/template.type';
import LayerHelpers from '../helpers/layer.helpers';
import { AdobeLayer } from '../types/layer.type';

/**
 * 28-02-2024 - 14:30
 * Add parent composition to dynamic option.
 * @param template - The whole template.
 * @returns New template
 */
const addParentCompToDynamicOption = (template: Template): Template => {
    if (!template['interfaceSetup']) {
        return template;
    }

    if (template.templateData.type !== 'dynamicAfterEffects') {
        return template;
    }

    function addFrameName(inputs: Input[]) {
        inputs.forEach((input) => {
            if (!input['frameName']) {
                input.frameName = input['composition'] as string;
                delete input['composition'];
            }

            if (input.children) {
                addFrameName(input.children);
            }

            if (input.layer?.key === undefined) {
                return;
            }

            const foundLayer = LayerHelpers.findLayer(input.layer.key, false, template.layers) as AdobeLayer;

            if (!foundLayer) {
                return;
            }

            const containers = (() => {
                if (!['text', 'image', 'video', 'audio'].includes(input.attribute)) {
                    return [input.frame];
                }

                return Object.keys(template.layers).filter((key) =>
                    template.layers[key].some((layer) => {
                        if ('originalName' in layer) {
                            const adobeLayer = layer as AdobeLayer;
                            return adobeLayer.originalName === foundLayer.originalName;
                        }
                        return false;
                    })
                );
            })();

            const containerName = containers.map((container) => template.compositions?.find((comp) => comp.key === container)?.title || '').join(', ');

            input.frameName = containerName;
        });
    }

    Object.keys(template['interfaceSetup']).forEach((frame) => {
        const inputs = template['interfaceSetup'][frame];
        addFrameName(inputs);
    });

    return template;
};

export default addParentCompToDynamicOption;
