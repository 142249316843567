import unset from 'lodash/unset';
import Template from 'components/template-designer/types/template.type';
import { MigrateFunction, MigrationScripts } from '../types/migrateFunction.type';
import { MIGRATION_SCRIPTS_ORDER } from '../migrateTemplate';

/**
 * This is the base function for each migration function that needs to be ran
 * It checks if the migration has already been ran and if not, it will run the migration
 * When the migration is done it adds the name of the migration to the finishedMigrations array
 */
const runMigration = (template: Template, name: MigrationScripts, migrateFunction: MigrateFunction): Template => {
    let finishedMigrations = template.templateSetup.finishedMigrations || [];

    // If the migration version is a number, we need to convert that to the finishedMigrations array.
    if (template.templateSetup['migrationVersion'] && typeof template.templateSetup['migrationVersion'] === 'number') {
        finishedMigrations = MIGRATION_SCRIPTS_ORDER.slice(0, template.templateSetup['migrationVersion']);
        template.templateSetup.finishedMigrations = finishedMigrations;
        unset(template.templateSetup, 'migrationVersion');
    }

    /**
     * 'convertInputModeToCropMode' needs to run every time the template opens. Because there was an issue with input that it still added the input mode.
     * 'convertGuidelinesToLayoutGrid' needs to run every time the template opens. Because there was an issue with the migration script so guidelines would not be converted to a layout grid.
     * TODO: Added on 30-01-2024 - 16:00: Remove this in the future?
     */
    const migrationsToAlwaysRun: MigrationScripts[] = ['convertInputModeToCropMode', 'convertGuidelinesToLayoutGrid'];
    if (!migrationsToAlwaysRun.includes(name) && finishedMigrations.includes(name)) {
        return template;
    }

    template = migrateFunction(template) as Template;

    return finishMigration(template, name);
};

const finishMigration = (template: Template, finishedMigration: MigrationScripts): Template => {
    /**
     * Update the migration version to the last ran migration script.
     * This version is to indicate whether we should rerun the old migration scripts.
     */
    if (!template.templateSetup.finishedMigrations) {
        template.templateSetup.finishedMigrations = [];
    }

    if (!template.templateSetup.finishedMigrations.includes(finishedMigration)) {
        template.templateSetup.finishedMigrations.push(finishedMigration);
    }

    return template;
};

export { runMigration };
