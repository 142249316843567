import merge from 'lodash/merge';
import Layer, { AfterEffectsLayerTypes, InDesignLayerTypes, LayerTypes } from '../types/layer.type';
import Template, { Layers } from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

type OldLayerTypes =
    | 'rectangle'
    | 'rectangle_rounded'
    | 'circle'
    | 'line'
    | 'heading'
    | 'subheading'
    | 'body_text'
    | 'small_body_text'
    | 'overline'
    | 'caption'
    | 'headline'
    | 'button'
    | 'button_text'
    | 'button_icon'
    | 'button_contained_rounded'
    | 'button_outlined_rounded'
    | 'button_text_only';

/**
 * 14-11-2023 - 16:30
 * Change old layer types to new.
 * @param template - The whole template.
 * @returns New template
 */
const changeLayers: MigrateFunction = (template: Template): Template => {
    /**
     * Recursively migrate layer types.
     * @param layers - The layers to migrate.
     * @returns The migrated layers.
     */
    function migrateLayerTypes(layers: Layer[]) {
        return layers.map((layer) => {
            // Map old layer types to new layer types
            const newType = mapOldToNewType(layer.type);

            // Remove old data.
            delete layer['copyOf'];
            delete layer['groupType'];

            // Recursively migrate children
            const newChildren = layer.children ? migrateLayerTypes(layer.children) : [];

            // Return the updated layer
            return merge(layer, { type: newType, children: newChildren });
        });
    }

    /**
     * Map old layer types to new layer types.
     * @param oldType - The old layer type.
     * @returns The new layer type.
     */
    function mapOldToNewType(oldType: OldLayerTypes | LayerTypes | AfterEffectsLayerTypes | InDesignLayerTypes): LayerTypes {
        switch (oldType) {
            case 'rectangle':
            case 'rectangle_rounded':
            case 'circle':
            case 'line':
                return 'shape';
            default:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return oldType;
        }
    }

    const newLayers = Object.keys(template.layers).reduce((frames, frameType) => {
        frames[frameType] = migrateLayerTypes(template.layers[frameType]);
        return frames;
    }, {} as Layers);

    template.layers = newLayers;

    return template;
};

export default changeLayers;
