import LayerProperties, { Border } from '../types/layerProperties.type';
import Template from '../types/template.type';
import cloneDeep from '../utils/cloneDeep';
import { MigrateFunction } from './types/migrateFunction.type';

type oldBorderWidth = { value: number; unit: string };
/**
 * Convert border {value, unit} to {left: {value, unit} etc..} border with four sides.
 * @param borderWidth - The border width to convert.
 * @returns new border width with four sides.
 */
const handleChangeToSides = (borderWidth): Border['borderWidth'] => {
    const oldBorderWidth: oldBorderWidth = cloneDeep(borderWidth as oldBorderWidth);

    return {
        left: { value: oldBorderWidth.value, unit: oldBorderWidth.unit },
        right: { value: oldBorderWidth.value, unit: oldBorderWidth.unit },
        top: { value: oldBorderWidth.value, unit: oldBorderWidth.unit },
        bottom: { value: oldBorderWidth.value, unit: oldBorderWidth.unit }
    };
};

/**
 * 16-01-2024 - 14:00
 * Convert border {value, unit} to {left: {value, unit} etc..} border with four sides.
 * @param template - The whole template.
 * @returns New template
 */
const convertBorderWidthToObject: MigrateFunction = (template: Template): Template => {
    Object.values(template.layerProperties).forEach((format) => {
        Object.values(format).forEach((frameType) => {
            const frameBorder = frameType.properties?.border;
            if (frameBorder?.borderWidth) {
                frameBorder.borderWidth = handleChangeToSides(frameBorder.borderWidth);
            }
            Object.entries(frameType).forEach(([layerKey, layer]) => {
                if (layerKey !== 'properties') {
                    const layerBorder = (layer as LayerProperties).properties?.border;
                    if (layerBorder?.borderWidth) {
                        layerBorder.borderWidth = handleChangeToSides(layerBorder.borderWidth);
                    }
                }
            });
        });
    });

    return template;
};

export default convertBorderWidthToObject;
