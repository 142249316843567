import getCssColor from 'components/input/ColorSelector/utils/getCssColor';
import { convertColorToRgba } from 'helpers/colors';
import { Attributes } from '../types/attribute.type';
import {
    AlertInput,
    BaseSettings,
    ColorSettings,
    DividerInput,
    DividerSettings,
    DynamicLayerInput,
    FeedItemSelectorInput,
    FieldInput,
    GroupInput,
    GroupSettings,
    TextSettings,
    SelectSettings,
    AssetGalleryInputSettings
} from '../types/dynamicLayer.type';
import Layer from '../types/layer.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

interface OldInput {
    attribute: Attributes;
    key: string;
    model?: string;
    label: string;
    layer?: Layer;
    frame?: string;
    type: 'field' | 'assetGalleryInput' | 'feedSelectorInput' | 'group' | 'divider' | 'text' | 'textMultiLanguage' | 'alert';
    children?: OldInput[];
    settings: OldInputSettings;
    [key: string]: unknown;
}

export interface OldInputSettings {
    type: string;
    label?: string;
    model?: string;
    defaultValue?: string | number | undefined;
    display?: 'grid' | 'list';
    [key: string]: unknown;
}

const attributesThatShouldBecomeButtonGroup = [
    'textAlign',
    'horizontalAlign',
    'verticalAlign',
    'textDecoration',
    'textDecorationStyle',
    'borderStyle',
    'flexDirection',
    'justifyContent',
    'alignItems'
];

/**
 * 21-05-2024 - 11:00
 * Convert interface setup to dynamic layers
 * @param template - The whole template.
 * @returns New template
 */
const convertInterfaceSetupToDynamicLayers: MigrateFunction = (template: Template): Template => {
    if (template.dynamicLayers === undefined) {
        template.dynamicLayers = {
            base: []
        };
    }

    Object.keys(template['interfaceSetup']).forEach((frameTypeKey) => {
        const inputs = template['interfaceSetup'][frameTypeKey] as OldInput[];

        if (template.dynamicLayers[frameTypeKey] === undefined) {
            template.dynamicLayers[frameTypeKey] = [];
        }

        /**
         * Transform old inputs to new inputs.
         * @param oldInputs - Old inputs.
         * @returns New inputs.
         */
        function transformInput(oldInputs: OldInput[]): DynamicLayerInput[] {
            const transformSettings = (oldSettings: OldInputSettings, type: string, attribute: string): BaseSettings => {
                const baseSettings: BaseSettings = {
                    ...oldSettings,
                    type
                };

                if (oldSettings?.['readOnly']) {
                    baseSettings.readOnly = oldSettings['readOnly'] as boolean | undefined;
                }

                if (oldSettings?.['languageSpecific']) {
                    baseSettings.languageSpecific = oldSettings['languageSpecific'] as boolean;
                }

                if (type === 'assetGalleryInput') {
                    if (oldSettings.contentSpaceCollectionQuery) {
                        (baseSettings as AssetGalleryInputSettings).canUseContentSpace = true;
                    }
                }

                if (type === 'text') {
                    (baseSettings as TextSettings).useValueObject = false;
                }

                if (oldSettings && 'options' in oldSettings && !oldSettings.options?.[0]?.key) {
                    (baseSettings as SelectSettings).options = Object.entries(oldSettings.options as object).map(([key, value]) => ({
                        key: key,
                        value: value as string
                    }));
                }

                if (
                    oldSettings &&
                    'options' in oldSettings &&
                    [
                        'borderRadius',
                        'borderWidth',
                        'columnGap',
                        'fontSize',
                        'frameAlignment',
                        'frameFitting',
                        'opacity',
                        'rotation',
                        'rowGap',
                        'scale',
                        'text'
                    ].includes(attribute)
                ) {
                    //Translate the options to key, value pairs
                    (baseSettings as SelectSettings).options.unshift({ key: '', value: '' });
                }

                if (attribute && typeof attribute === 'string' && attribute.toLowerCase().includes('color')) {
                    //Convert the colors to strings
                    (baseSettings as ColorSettings).colors = (baseSettings as ColorSettings).colors?.map((color) => {
                        return convertColorToRgba(getCssColor(color)) || '#ffffff';
                    });
                    //Add a first empty color for the template value
                    if ('colors' in baseSettings) {
                        (baseSettings as ColorSettings).colors?.unshift('#ffffff');
                    }
                }

                return baseSettings;
            };

            const transform = (item: OldInput): DynamicLayerInput => {
                const settingsType = (() => {
                    if (attributesThatShouldBecomeButtonGroup.includes(item.attribute)) {
                        return 'buttonGroup';
                    }

                    if (item.settings?.type) {
                        return item.settings.type;
                    }

                    return item.type;
                })();

                const baseInput: unknown = {
                    key: item.key,
                    label: item.label,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    type: item.type as any,
                    settings: {}
                };

                if (item.settings?.tooltip) {
                    (baseInput as FieldInput).tooltip = item.settings.tooltip as string;
                    delete item.settings.tooltip;
                }

                if (item.settings?.helperText) {
                    (baseInput as FieldInput).helperText = item.settings.helperText as string;
                    delete item.settings.helperText;
                }

                // We don't want to delete the model for Adobe templates because that has been generated when adding inputs.
                if (!['dynamicAfterEffects', 'dynamicInDesign'].includes(template.templateData.type) && item.settings?.model) {
                    delete item.settings.model;
                }

                if (item.settings?.label) {
                    delete item.settings.label;
                }

                if (item.frame) {
                    (baseInput as FieldInput).frame = item.frame;
                }

                if (item.frameName) {
                    (baseInput as FieldInput).frameName = item.frameName as string;
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                baseInput.settings = transformSettings(item.settings, settingsType, item.attribute);

                switch (item.type) {
                    case 'group':
                        return {
                            ...(baseInput as GroupInput),
                            type: 'group',
                            children: item.children ? item.children.map(transform) : [],
                            attribute: 'group',
                            settings: {
                                ...(baseInput as GroupInput).settings,
                                type: 'switch'
                            } as GroupSettings
                        };
                    case 'divider':
                        return {
                            ...(baseInput as DividerInput),
                            type: 'divider',
                            attribute: 'divider',
                            settings: {
                                ...(baseInput as DividerInput).settings,
                                type: 'divider'
                            } as DividerSettings
                        };
                    case 'feedSelectorInput':
                        return {
                            ...(baseInput as FeedItemSelectorInput),
                            type: 'feedSelectorInput',
                            attribute: 'feedSelectorInput',
                            settings: {
                                ...(baseInput as FeedItemSelectorInput).settings
                            } as FeedItemSelectorInput['settings']
                        };
                    case 'alert':
                        return {
                            ...(baseInput as AlertInput),
                            type: 'alert',
                            attribute: 'alert',
                            settings: {
                                ...(baseInput as AlertInput).settings
                            } as AlertInput['settings']
                        };
                    case 'field': {
                        const newInput: DynamicLayerInput = {
                            ...(baseInput as FieldInput),
                            type: 'field',
                            layerKey: item.layer ? item.layer.key : '',
                            children: item.children ? item.children.map(transform) : [],
                            attribute: item.attribute,
                            settings: {
                                ...(baseInput as FieldInput).settings
                            } as FieldInput['settings']
                        };

                        if (item.overwriteModel) {
                            newInput.overwriteModel = item.overwriteModel as string;
                        }

                        return newInput;
                    }
                    default:
                        throw new Error(`Unknown type: ${item.type}`);
                }
            };

            return oldInputs.map(transform);
        }

        template.dynamicLayers[frameTypeKey] = transformInput(inputs);
    });

    delete template['interfaceSetup'];

    return template;
};

export default convertInterfaceSetupToDynamicLayers;
