import LayerProperties, { VerticalAlignOptions } from '../types/layerProperties.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

enum OldVerticalAlignOptions {
    Top = 'top',
    Center = 'center',
    Bottom = 'bottom'
}

/**
 * 26-08-2024 - 16:30
 * Change verticalAlign center to middle.
 * @param template - The whole template.
 * @returns New template
 */
const verticalAlignCenterToMiddle: MigrateFunction = (template: Template): Template => {
    Object.values(template.layerProperties).forEach((format) => {
        Object.values(format).forEach((frameType) => {
            Object.entries(frameType).forEach(([layerKey, layer]) => {
                if (layerKey !== 'properties') {
                    const properties = (layer as LayerProperties).properties;
                    if (!properties) return;

                    const verticalAlign = properties.verticalAlign as unknown as OldVerticalAlignOptions;

                    if (verticalAlign && verticalAlign === OldVerticalAlignOptions.Center) {
                        properties.verticalAlign = VerticalAlignOptions.Middle;
                    }
                }
            });
        });
    });

    return template;
};

export default verticalAlignCenterToMiddle;
