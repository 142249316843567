import Input from '../types/input.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 13-04-2023 - 13:00
 * Convert composition in the input to frame.
 * @param template - The whole template.
 * @returns New template
 */
const convertCompositionToFrame: MigrateFunction = (template: Template): Template => {
    if (!template['interfaceSetup']) {
        return template;
    }

    function convertFrame(inputs: Input[]) {
        inputs.forEach((input) => {
            if (input['composition']) {
                input.frame = input['composition'] as string;
                delete input['composition'];
            }

            if (input.children && input.children.length) {
                convertFrame(input.children);
            }
        });
    }

    Object.keys(template['interfaceSetup']).forEach((frame) => {
        const inputs = template['interfaceSetup'][frame];
        convertFrame(inputs);
    });

    return template;
};

export default convertCompositionToFrame;
