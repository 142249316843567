import Input from '../types/input.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 08-12-2023 - 15:00
 * Convert brand guide font input keys.
 * @param template - The whole template.
 * @returns New template
 */
const convertBrandGuideFontInputs: MigrateFunction = (template: Template): Template => {
    const brandGuideFonts = template.brandGuide?.fonts;
    if (!brandGuideFonts) return template;

    if (!template['interfaceSetup']) {
        return template;
    }

    function convertInputs(inputs: Input[]) {
        inputs.forEach((input) => {
            if (
                // Check for correct input.
                input.attribute === 'fontFamily' &&
                typeof input.settings.fontSource === 'string' &&
                input.settings.fontSource.includes('brandguide') &&
                // Check if options exist.
                input.settings.options &&
                typeof input.settings.options === 'object' &&
                Object.keys(input.settings.options).length > 0
            ) {
                // Convert options so it has the correct keys.
                input.settings.options = Object.keys(input.settings.options).reduce(
                    (options, key) => {
                        if (!input.settings.options) return options;
                        const option = input.settings.options[key];
                        const brandGuideFont = brandGuideFonts?.find((font) => font.value === option);
                        if (!brandGuideFont) {
                            options[key] = option;
                            return options;
                        }

                        options[brandGuideFont.key] = brandGuideFont.value;
                        return options;
                    },
                    {} as { [key: string]: string }
                );
            }

            // Loop through children.
            if (input.children && input.children.length > 0) convertInputs(input.children);
        });
    }

    // Loop through interface setup.
    Object.keys(template['interfaceSetup']).forEach((frameType) => {
        const inputs = template['interfaceSetup'][frameType];
        convertInputs(inputs);
    });

    return template;
};

export default convertBrandGuideFontInputs;
