import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 08-05-2023 - 15:00
 * Delete the list of fonts in the designer settings object
 * @param template - The whole template.
 * @returns New template
 */
const deleteDesignerSettingsFonts: MigrateFunction = (template: Template): Template => {
    delete template.designerSettings['fonts'];
    return template;
};

export default deleteDesignerSettingsFonts;
