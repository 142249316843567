import { DynamicLayerInput, SelectSettings } from '../types/dynamicLayer.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 21-06-2024 - 15:15
 * Convert font family input to have default value / empty first value.
 * @param template - The whole template.
 * @returns New template
 */
const convertFontFamilyInputToHaveDefault: MigrateFunction = (template: Template): Template => {
    if (!template['dynamicLayers']) {
        return template;
    }

    function convertFontFamilyInput(inputs: DynamicLayerInput[]) {
        inputs.forEach((input) => {
            if (input?.attribute === 'fontFamily') {
                if (!(input.settings as SelectSettings).options) {
                    (input.settings as SelectSettings).options = [];
                }

                (input.settings as SelectSettings).options.unshift({ value: '', key: '' });
            }

            if ('children' in input && input.children.length) {
                convertFontFamilyInput(input.children);
            }
        });
    }

    Object.keys(template['dynamicLayers']).forEach((frame) => {
        const inputs = template['dynamicLayers'][frame];
        convertFontFamilyInput(inputs);
    });

    return template;
};

export default convertFontFamilyInputToHaveDefault;
