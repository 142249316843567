import { LayoutGridUnitOptions } from '../types/formatProperties.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 16-11-2023 - 13:00
 * Add empty format sets to existing formats and custom formats in designer settings.
 * @param template - The whole template.
 * @returns New template
 */
const convertGuidelinesToUnits: MigrateFunction = (template: Template): Template => {
    Object.values(template.layerProperties).forEach((format) => {
        Object.values(format).forEach((frameTypeProperties) => {
            // eslint-disable-next-line
            // @ts-ignore
            if (frameTypeProperties.properties?.guidelines && frameTypeProperties.properties.guidelines.length) {
                // eslint-disable-next-line
                // @ts-ignore
                frameTypeProperties.properties?.guidelines.forEach((guideline) => {
                    guideline.value = {
                        // eslint-disable-next-line
                        // @ts-ignore
                        value: guideline.px,
                        unit: LayoutGridUnitOptions.Px
                    };

                    // eslint-disable-next-line
                    // @ts-ignore
                    delete guideline.px;
                });
            }
        });
    });

    return template;
};

export default convertGuidelinesToUnits;
