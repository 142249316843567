import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 21-04-2023 - 14:00
 * Convert upload by language to image and video inputs.
 * @param template - The whole template.
 * @returns New template
 */
const convertUploadByLanguage: MigrateFunction = (template: Template): Template => {
    if (!template['interfaceSetup']) {
        return template;
    }

    /**
     * First check if uploadByLanguage is not undefined and false.
     * If it is then return the template immediately.
     * Otherwise it puts all the inputs to false again.
     */
    if (template.designerSettings['uploadByLanguage'] !== undefined && template.designerSettings['uploadByLanguage'] === false) {
        delete template.designerSettings['uploadByLanguage'];
        return template;
    }

    /**
     * Find all assetGalleryInputs and set the languageSpecific to the value of uploadByLanguage.
     */
    Object.keys(template['interfaceSetup']).forEach((frameType) => {
        const inputs = template['interfaceSetup'][frameType];
        inputs.forEach((input) => {
            if (input?.settings?.type === 'assetGalleryInput') {
                input.settings.languageSpecific = template.designerSettings['uploadByLanguage'];
            }
        });
    });

    delete template.designerSettings['uploadByLanguage'];
    return template;
};

export default convertUploadByLanguage;
