/**
 * To Store Google Fonts in memory.
 */
export class GoogleFonts {
    /**
     * Google Fonts JSON.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static googleFonts: any[];

    /**
     * Get Google Fonts.
     * @returns Google Fonts JSON.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static get(): any[] {
        return this.googleFonts;
    }

    /**
     * Set Google Fonts.
     * @param scene - Google Fonts JSON.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static set(googleFonts: any[]): void {
        this.googleFonts = googleFonts;
    }
}
