import { FontType } from '../types/font.type';
import LayerProperties from '../types/layerProperties.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

type OldFontType = FontType & 'brandGuide_general';

/**
 * 13-05-2024 - 12:00
 * Convert brand guide general font source to brand guide.
 * @param template - The whole template.
 * @returns New template
 */
const convertBrandGuideGeneralToBrandGuide: MigrateFunction = (template: Template): Template => {
    Object.keys(template.layerProperties).forEach((formatKey) => {
        Object.keys(template.layerProperties[formatKey]).forEach((frameTypeKey) => {
            Object.keys(template.layerProperties[formatKey][frameTypeKey]).forEach((layerKey) => {
                if (layerKey === 'properties') return;
                const layerProperties = template.layerProperties[formatKey][frameTypeKey][layerKey] as LayerProperties;

                //TODO: DELETE AFTER WE CREATED A WRAPPER
                if (Array.isArray(layerProperties) || Array.isArray(layerProperties.properties) || !layerProperties || !layerProperties.properties) return;
                if (!('textStyling' in layerProperties.properties)) return;

                const normalFontSource = layerProperties.properties.textStyling?.normal?.fontSource as OldFontType;
                const highlightedFontSource = layerProperties.properties.textStyling?.highlighted?.fontSource as OldFontType;

                if (normalFontSource === 'brandGuide_general') {
                    layerProperties.properties.textStyling.normal.fontSource = 'brandGuide';
                }

                if (highlightedFontSource === 'brandGuide_general') {
                    layerProperties.properties.textStyling.highlighted.fontSource = 'brandGuide';
                }
            });
        });
    });

    return template;
};

export default convertBrandGuideGeneralToBrandGuide;
