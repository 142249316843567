import { LayoutGridOptions } from '../types/formatProperties.type';
import Template from '../types/template.type';
import cloneDeep from '../utils/cloneDeep';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 09-02-2024 - 13:00
 * Move guidelines into the object layoutGrid to be able to save more layoutGrid options later on.
 * @param template - The whole template.
 * @returns New template
 */
const convertGuidelinesToLayoutGrid: MigrateFunction = (template: Template): Template => {
    Object.values(template.layerProperties).forEach((format) => {
        Object.values(format).forEach((frameTypeProperties) => {
            // eslint-disable-next-line
            // @ts-ignore
            if (frameTypeProperties.properties?.guidelines) {
                frameTypeProperties.properties.layoutGrid = {
                    ...frameTypeProperties.properties.layoutGrid,
                    activeLayout: LayoutGridOptions.Guidelines,
                    // eslint-disable-next-line
                    // @ts-ignore
                    guidelines: cloneDeep(frameTypeProperties.properties.guidelines)
                };
                // eslint-disable-next-line
                // @ts-ignore
                delete frameTypeProperties.properties.guidelines;
            }
        });
    });

    return template;
};

export default convertGuidelinesToLayoutGrid;
