import Input from '../types/input.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 10-11-2023 - 16:15
 * Convert input "mode" to "cropMode".
 * @param template - The whole template.
 * @returns New template
 */
const convertInputModeToCropMode: MigrateFunction = (template: Template): Template => {
    if (!template['interfaceSetup']) {
        return template;
    }

    function convertInputCropMode(inputs: Input[]) {
        inputs.forEach((input) => {
            if (input?.settings?.type === 'assetGalleryInput') {
                if (input.settings.mode !== undefined) {
                    if (input.settings.mode === 'ratioBased') {
                        input.settings.cropMode = 'fixed';
                    } else {
                        input.settings.cropMode = input.settings.mode as string;
                    }
                    delete input.settings.mode;
                } else if (input.settings.cropMode !== undefined) {
                    if (input.settings.cropMode === 'ratioBased') {
                        input.settings.cropMode = 'fixed';
                    }
                }
            }

            if (input.children && input.children.length) {
                convertInputCropMode(input.children);
            }
        });
    }

    Object.keys(template['interfaceSetup']).forEach((frame) => {
        const inputs = template['interfaceSetup'][frame];
        convertInputCropMode(inputs);
    });

    return template;
};

export default convertInputModeToCropMode;
