import get from 'lodash/get';
import defaultTextStyling from '../config/defaultTextStyling';
import LayerHelpers from '../helpers/layer.helpers';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 04-01-2023 - 13:00
 * Converts text layers to new format.
 * Previsouly this was done every time the template was loaded but now we do it once in the migration.
 * @param template - The whole template.
 * @returns New template
 */
const convertTextLayers: MigrateFunction = (template: Template): Template => {
    const textProperties = Object.keys(defaultTextStyling);

    Object.keys(template.layerProperties).forEach((formatKey) => {
        Object.keys(template.layerProperties[formatKey]).forEach((frameTypeKey) => {
            Object.keys(template.layerProperties[formatKey][frameTypeKey]).forEach((layerKey) => {
                if (layerKey === 'properties') return;

                const layer = LayerHelpers.findLayer(layerKey, frameTypeKey, template.layers);
                if (!layer || layer.type !== 'text') return;

                const properties = get(template, `layerProperties.${formatKey}.${frameTypeKey}.${layerKey}.properties`);

                if (!properties || 'textStyling' in properties) return;

                const textStyling = {
                    normal: {},
                    highlighted: {}
                };

                Object.keys(properties).forEach((item) => {
                    if (textProperties.includes(item)) {
                        textStyling.normal[item] = properties[item];
                        delete properties[item];
                    }
                });

                // Set it as any because it expects textStyling to have all properties.
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (properties as any).textStyling = textStyling;
            });
        });
    });

    return template;
};

export default convertTextLayers;
