import { AdobeLayer } from '../types/layer.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 13-03-2024 - 15:00
 * Add hasLayerName value to old After Effects template layers.
 * @param template - The whole template.
 * @returns New template
 */
const addHasLayerNameToAfterEffectsLayers: MigrateFunction = (template: Template): Template => {
    if (template.templateData.type !== 'dynamicAfterEffects') return template;

    Object.keys(template.layers).forEach((comp) => {
        template.layers[comp].forEach((layer: AdobeLayer) => {
            layer.hasLayerName = layer.originalName ? true : false;
        });
    });

    return template;
};

export default addHasLayerNameToAfterEffectsLayers;
