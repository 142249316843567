import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 11-04-2023 - 13:50
 * If it is an Adobe template, move download url and file to template setup.
 *
 * Move adobe template identifier to adobe template setup
 *
 * @param template - The whole template.
 * @returns New template
 */
const adobeToTemplateSetup: MigrateFunction = (template: Template): Template => {
    if (!['dynamicAfterEffects', 'dynamicInDesign'].includes(template.templateData.type)) {
        return template;
    }

    template.templateSetup.adobe = {
        identifier: template.templateData.identifier,
        ...template.templateSetup.adobe,
        downloadUrl: template.designerSettings['downloadUrl'] || template.templateSetup.adobe?.downloadUrl,
        file: template.designerSettings['file'] || template.templateSetup.adobe?.file
    };

    delete template.designerSettings['downloadUrl'];
    delete template.designerSettings['file'];

    return template;
};

export default adobeToTemplateSetup;
