import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 03-06-2023 - 14:30
 * Add or remove data.
 * @param template - The whole template.
 * @returns New template
 */
const addRemoveData: MigrateFunction = (template: Template): Template => {
    if (!['dynamicAfterEffects', 'dynamicInDesign'].includes(template.templateData.type)) {
        return template;
    }

    // Remove originalIdentifier because it is not used anymore.
    if (template.templateSetup['originalIdentifier'] !== undefined) {
        delete template.templateSetup['originalIdentifier'];
    }

    // Remove useUnsplash because we are getting it now from the setup.
    if (template.designerSettings['useUnsplash'] !== undefined) {
        delete template.designerSettings['useUnsplash'];
    }

    // Remove useAprimo because only Philips uses it and we are checking on accountId.
    if (template.designerSettings['useAprimo'] !== undefined) {
        delete template.designerSettings['useAprimo'];
    }

    return template;
};

export default addRemoveData;
