import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 16-11-2023 - 13:00
 * Add empty format sets to existing formats and custom formats in designer settings.
 * @param template - The whole template.
 * @returns New template
 */
const addFormatSets: MigrateFunction = (template: Template): Template => {
    template.formats.forEach((format) => {
        if (!format.sets) {
            format.sets = [];
        }
    });

    if (template.designerSettings.customFormats) {
        template.designerSettings.customFormats.forEach((format) => {
            if (!format.sets) {
                format.sets = [];
            }
        });
    }

    return template;
};

export default addFormatSets;
