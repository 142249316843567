import Template, { ExportForPlatform } from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 04-08-2024 - 15:00
 * Convert Xander and Google Studio export to ExportForPlatform.
 * @param template - The whole template.
 * @returns New template
 */
const convertToExportForPlatform: MigrateFunction = (template: Template): Template => {
    const xanderSetting = template.designerSettings['xandrTemplate'];
    const googleStudioSetting = template.designerSettings['enableGoogleStudio'];

    if (xanderSetting === true) {
        template.designerSettings.exportForPlatform = ExportForPlatform.Xandr;
    }

    if (googleStudioSetting === true) {
        template.designerSettings.exportForPlatform = ExportForPlatform.Studio;
    }

    return template;
};

export default convertToExportForPlatform;
