import cloneDeep from 'components/template-designer/utils/cloneDeep';
import Template from '../types/template.type';
import adobeToTemplateSetup from './adobeToTemplateSetup';
import convertCompositionToFrame from './convertCompositionToFrame';
import convertUploadByLanguage from './convertUploadByLanguage';
import deleteDesignerSettingsFonts from './deleteDesignerSettingsFonts';
import addRemoveData from './addRemoveData';
import convertKeyframeEasing from './convertKeyframeEasing';
import addFormatSets from './addFormatSets';
import changeLayers from './changeLayers';
import { runMigration } from './helpers/migration.helper';
import convertInputModeToCropMode from './convertInputModeToCropMode';
import convertBrandGuideFontInputs from './convertBrandGuideFontInputs';
import convertTextLayers from './convertTextLayers';
import convertBorderWidthToObject from './convertBorderWidthToObject';
import { MigrationScripts } from './types/migrateFunction.type';
import convertGuidelinesToUnits from './convertGuidelinesToUnits';
import convertGuidelinesToLayoutGrid from './convertGuidelinesToLayoutGrid';
import addHasLayerNameToAfterEffectsLayers from './addHasLayerNameToAfterEffectsLayers';
import addParentCompToDynamicOption from './addParentCompToDynamicOption';
import convertBrandGuideGeneralToBrandGuide from './convertBrandGuideGeneralToBrandGuide';
import convertInterfaceSetupToDynamicLayers from './convertInterfaceSetupToDynamicLayers';
import convertBrandGuideFonts from './convertBrandGuideFonts';
import convertFontFamilyInputToHaveDefault from './convertFontFamilyInputToHaveDefault';
import removeTypingAnimations from './removeTypingAnimations';
import convertToExportForPlatform from './convertToExportForPlatform';
import verticalAlignCenterToMiddle from './verticalAlignCenterToMiddle';

export const MIGRATION_SCRIPTS_ORDER: MigrationScripts[] = [
    'addRemoveData',
    'adobeToTemplateSetup',
    'convertCompositionToFrame',
    'convertUploadByLanguage',
    'deleteDesignerSettingsFonts',
    'convertKeyframeEasing',
    'changeLayers',
    'addFormatSets',
    'convertInputModeToCropMode',
    'convertBrandGuideFontInputs',
    'convertPositionKeyframes',
    'convertBorderWidthToObject',
    'convertTextLayers',
    'convertGuidelinesToUnits',
    'convertGuidelinesToLayoutGrid',
    'addHasLayerNameToAfterEffectsLayers',
    'addParentCompToDynamicOption',
    'convertBrandGuideGeneralToBrandGuide',
    'convertInterfaceSetupToDynamicLayers',
    'convertBrandGuideFonts',
    'removeTypingAnimations',
    'convertToExportForPlatform',
    'verticalAlignCenterToMiddle'
];

/**
 * Calls migration functions in order.
 * @param template - The whole template.
 * @returns New template.
 */
const migrateTemplate = (template: Template): Template => {
    template = cloneDeep(template) as Template;

    template = runMigration(template, 'addRemoveData', addRemoveData); // 03-06-2023 - 14:30
    template = runMigration(template, 'adobeToTemplateSetup', adobeToTemplateSetup); // 11-04-2023 - 13:50
    template = runMigration(template, 'convertCompositionToFrame', convertCompositionToFrame); // 13-04-2023 - 13:00
    template = runMigration(template, 'convertUploadByLanguage', convertUploadByLanguage); // 21-04-2023 - 14:00
    template = runMigration(template, 'deleteDesignerSettingsFonts', deleteDesignerSettingsFonts); // 08-05-2023 - 15:00
    template = runMigration(template, 'convertKeyframeEasing', convertKeyframeEasing); // 11-07-2023 - 17:00
    template = runMigration(template, 'changeLayers', changeLayers); // 14-11-2023 - 16:30
    template = runMigration(template, 'addFormatSets', addFormatSets); // 16-11-2023 - 13:00
    template = runMigration(template, 'convertInputModeToCropMode', convertInputModeToCropMode); // 10-11-2023 - 16:15
    template = runMigration(template, 'convertBrandGuideFontInputs', convertBrandGuideFontInputs); // 08-12-2023 - 15:00
    template = runMigration(template, 'convertBorderWidthToObject', convertBorderWidthToObject); // 16-01-2024 - 14:00
    template = runMigration(template, 'convertTextLayers', convertTextLayers); // 04-01-2023 - 13:00
    template = runMigration(template, 'convertGuidelinesToUnits', convertGuidelinesToUnits); // 16-11-2023 - 13:00 (after addFormatSets
    template = runMigration(template, 'addParentCompToDynamicOption', addParentCompToDynamicOption); // 27-03-2024 - 10:00
    //! This migration is done on canvas mount because we need to know the client width for this migration.
    // template = runMigration(template, 'convertPositionKeyframes', convertPositionKeyframes); // 28-11-2023 16:20
    template = runMigration(template, 'convertGuidelinesToLayoutGrid', convertGuidelinesToLayoutGrid); // 09-02-2024 - 13:00
    template = runMigration(template, 'addHasLayerNameToAfterEffectsLayers', addHasLayerNameToAfterEffectsLayers); // 13-03-2024 - 15:00
    template = runMigration(template, 'convertBrandGuideGeneralToBrandGuide', convertBrandGuideGeneralToBrandGuide); // 13-05-2024 - 12:00
    template = runMigration(template, 'convertInterfaceSetupToDynamicLayers', convertInterfaceSetupToDynamicLayers); // 21-05-2024 - 11:00
    template = runMigration(template, 'convertBrandGuideFonts', convertBrandGuideFonts); // 28-05-2024 - 11:00
    template = runMigration(template, 'convertFontFamilyInputToHaveDefault', convertFontFamilyInputToHaveDefault); // 21-06-2024 - 15:15
    template = runMigration(template, 'removeTypingAnimations', removeTypingAnimations); // 23-08-2024 - 10:30
    template = runMigration(template, 'verticalAlignCenterToMiddle', verticalAlignCenterToMiddle); // 26-08-2024 - 16:30
    template = runMigration(template, 'convertToExportForPlatform', convertToExportForPlatform); // 04-09-2024 - 15:00

    return template;
};

export default migrateTemplate;
