import LayerProperties from '../types/layerProperties.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 23-08-2024 - 10:30
 * Remove typing animations and animationEasing from the template.
 * @param template - The whole template.
 * @returns New template
 */
const removeTypingAnimations: MigrateFunction = (template: Template): Template => {
    Object.values(template.layerProperties).forEach((format) => {
        Object.values(format).forEach((frameType) => {
            Object.entries(frameType).forEach(([layerKey, layer]) => {
                if (layerKey !== 'properties') {
                    // Delete animationEasing if it exists
                    delete (layer as LayerProperties)['animationEasing'];

                    // Delete 'typing' animation if it exists
                    const animations = (layer as LayerProperties).animations;
                    if (animations && 'typing' in animations) {
                        delete animations.typing;
                    }
                }
            });
        });
    });

    return template;
};

export default removeTypingAnimations;
